import React from 'react';
import {Modal, Form, Table} from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import triggerNotification from 'actions/api/notifications/triggerNotification';
import {displayNotification} from 'actions/notifications';
import './notifications.scss';
import {connect} from 'react-redux';
import {DEFAULT_NOTIFICATION_TIMEOUT} from "../../../constants/notifications";

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            employees: [],
            selectedEmployees: [],
            searchTerm: '',
            selectAllChecked: false,
            isProcessing: false
        };
    }

    openModal = () => {
        this.setState({showModal: true});
        this.loadEmployees();
    }

    closeModal = () => {
        this.setState({showModal: false});
    }

    loadEmployees = async () => {
        const {selectedOrg} = this.props;
        const {organisation_id} = selectedOrg || {};

        try {
            const employees = await listEmployees(organisation_id);
            this.setState({employees});
        } catch (err) {
            console.error('Failed to load employees');
        }
    }

    handleEmployeeSelection = (employeeId) => {
        const {selectedEmployees} = this.state;

        if (selectedEmployees.includes(employeeId)) {
            this.setState({
                selectedEmployees: selectedEmployees.filter(id => id !== employeeId),
                selectAllChecked: false
            });
        } else {
            this.setState({
                selectedEmployees: [...selectedEmployees, employeeId]
            });
        }
    }

    handleSelectAll = () => {
        const {employees, selectAllChecked} = this.state;
        if (!selectAllChecked) {
            const allEmployeeIds = employees.map(employee => employee.employee_id);
            this.setState({
                selectedEmployees: allEmployeeIds,
                selectAllChecked: true
            });
        } else {
            this.setState({
                selectedEmployees: [],
                selectAllChecked: false
            });
        }
    }

    handleSendNotification = async () => {
        const {selectedEmployees, employees} = this.state;
        const {selectedOrg, displayNotification} = this.props;
        const organisationId = selectedOrg.organisation_id;
        const orgType = selectedOrg.type;

        const notificationType = orgType ? orgType.split(':')[0] : '';
        const notificationName = `${notificationType}_new_products`;

        const selectedEmployeeEmails = employees
            .filter(employee => selectedEmployees.includes(employee.employee_id))
            .map(employee => employee.email);

        this.setState({isProcessing: true});

        try {
            await triggerNotification(selectedEmployeeEmails, notificationName, organisationId);
            this.setState({isProcessing: false});
            displayNotification({
                type: 'success',
                message: 'Notification Sent',
                description: 'The notification was sent successfully to the selected employees.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            this.closeModal();
        } catch (error) {
            console.error('Failed to send notification', error);
            this.setState({isProcessing: false});
            displayNotification({
                type: 'error',
                message: 'Notification Failed',
                description: 'There was an issue sending the notification. Please try again.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
        }
    }

    handleSearch = (event) => {
        this.setState({searchTerm: event.target.value});
    }

    getFilteredEmployees = () => {
        const {employees, searchTerm} = this.state;

        if (!searchTerm) {
            return employees;
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        return employees.filter(employee => {
            const {first_name, last_name, email, employee_id} = employee;
            return (
                first_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                last_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                email.toLowerCase().includes(lowerCaseSearchTerm) ||
                employee_id.toLowerCase().includes(lowerCaseSearchTerm)
            );
        });
    }

    render() {
        const {showModal, selectedEmployees, searchTerm, selectAllChecked, isProcessing} = this.state;
        const filteredEmployees = this.getFilteredEmployees();

        return (
            <div>
                <h1>Notifications</h1>

                <div className={'row marg-t'}>
                    <div className={'col-md-6'}>
                        <h4>New products launching at retail this month</h4>
                    </div>

                    <div className={'col-md-6'}>
                        <button className="submit btn btn-primary" onClick={this.openModal}>
                            Select Employees
                        </button>
                    </div>
                </div>

                <Modal show={showModal}
                       onHide={this.closeModal}
                       size="lg"
                       dialogClassName="modal-employee-list">
                    <Modal.Header closeButton>
                        <Modal.Title>Select Employees</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group controlId="search" className="search-container">
                            <Form.Control
                                type="text"
                                placeholder="Search employees by name, email, or ID"
                                value={searchTerm}
                                onChange={this.handleSearch}
                                disabled={isProcessing}
                            />
                            {searchTerm && (
                                <span className="clear-search" onClick={() => this.setState({searchTerm: ''})}>X</span>
                            )}
                        </Form.Group>

                        <Table striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th>
                                    {/*<Form.Check*/}
                                    {/*    type="checkbox"*/}
                                    {/*    checked={selectAllChecked}*/}
                                    {/*    onChange={this.handleSelectAll}*/}
                                    {/*    disabled={isProcessing}*/}
                                    {/*/>*/}
                                </th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Employee ID</th>
                                <th>Email</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredEmployees.length > 0 ? filteredEmployees.map(employee => (
                                <tr
                                    key={employee.employee_id}
                                    onClick={() => this.handleEmployeeSelection(employee.employee_id)}
                                    className={`employee-row ${selectedEmployees.includes(employee.employee_id) ? 'selected' : ''}`}
                                    style={{cursor: 'pointer'}}
                                >
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={selectedEmployees.includes(employee.employee_id)}
                                            onChange={() => this.handleEmployeeSelection(employee.employee_id)}
                                            disabled={isProcessing}
                                        />
                                    </td>
                                    <td>{employee.first_name}</td>
                                    <td>{employee.last_name}</td>
                                    <td>{employee.employee_id}</td>
                                    <td>
                      <span title={employee.email}>
                        {employee.email}
                      </span>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        There is no data to show right now.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-light" onClick={this.closeModal} disabled={isProcessing}>
                            Close
                        </button>
                        <button className="submit btn btn-primary" onClick={this.handleSendNotification}
                                disabled={isProcessing}>
                            {isProcessing ? 'Sending...' : 'Send Notification'}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    null,
    {displayNotification}
)(Notifications);
