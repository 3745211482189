import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Col, Container, Button, Modal,} from 'react-bootstrap';
import addUser from 'actions/api/users/create'
import addEmployee from 'actions/api/employees/create'

import listUsers from 'actions/api/users/list'
import {displayNotification} from 'actions/notifications';
import {DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS} from 'constants/notifications';
import {map} from "lodash";

class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUserFirstName: '',
            newUserLastName: '',
            newUserEmail: '',
            validEmail: false,
            invalidEmailMessage: false,
        }
    }

    validateEmail = (email) => {
        const stringValid = /^[\w\.\'\+\-]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        const { userListTableData } = this.props;
        const emailList = map(userListTableData, item => item.email);

        this.setState({
            validEmail: stringValid && !emailList.includes(email),
            invalidEmailMessage: emailList.includes(email) ? 'This email address already exists' : 'You have entered an invalid email address!'
        });
    }

    handleUpdateUserDetails = (newUserdata) => {
        this.setState(newUserdata);
    }

    handleSubmitUserDetails = () => {
        const {listUsers, organisationId = null, addUserCallback, togglePopUp} = this.props;
        const {newUserFirstName, newUserLastName, newUserEmail} = this.state;
        addUser({newUserFirstName, newUserLastName, newUserEmail}).then((newUser) => {
            let addEmpPromise = Promise.resolve();
            // If organisation id was supplied also add this user as an employee of that org
            if (organisationId) {
                const {user_id, information} = newUser;
                addEmpPromise = addEmployee(organisationId, {userId: user_id, information});
            }

            addEmpPromise.then(() => {
                if (addUserCallback) addUserCallback(newUser);
                // Update users global state
                listUsers(true);
                this.setState({
                    newUserFirstName: '',
                    newUserLastName: '',
                    newUserEmail: '',
                });
            });
            this.props.displayNotification({
                message: `Successfully added user ${organisationId ? `and created employee for this organisation` : ''}`,
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            togglePopUp();
        }).catch(err => {
            this.props.displayNotification({
                message: `Failed to add user ${organisationId ? `and employee` : ''}`,
                type: TYPE_ERROR,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            })
        });
    }

    render() {
        const {organisationId = null, show = false, togglePopUp,userListTableData} = this.props;
        const {newUserFirstName, newUserLastName, newUserEmail, validEmail,invalidEmailMessage} = this.state;
        return (
            <div className="x-add-org-modal">
                <Modal show={show} onHide={togglePopUp}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add User {organisationId ? 'And Employee' : ''}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridName">
                                        <div className="form-item">
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control
                                                placeholder="Enter user email"
                                                onChange={(e) => {
                                                    this.handleUpdateUserDetails({newUserEmail: e.target.value});
                                                    this.validateEmail(e.target.value);

                                                }}
                                                required={true}
                                                value={newUserEmail}
                                                type="email"
                                            />
                                            {!validEmail && newUserEmail.length > 0 && (
                                                <p className="error-label">
                                                    {invalidEmailMessage}
                                                </p>
                                            )}
                                        </div>


                                        <div className="form-item">
                                            <Form.Label>First Name *</Form.Label>
                                            <Form.Control
                                                placeholder="Enter user first name"
                                                onChange={(e) => {
                                                    this.handleUpdateUserDetails({newUserFirstName: e.target.value})
                                                }}
                                                required={true}
                                                value={newUserFirstName}
                                            />
                                        </div>

                                        <div className="form-item">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                placeholder="Enter user last name"
                                                onChange={(e) => {
                                                    this.handleUpdateUserDetails({newUserLastName: e.target.value})
                                                }}
                                                value={newUserLastName}
                                            />
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={togglePopUp}>
                            Close
                        </Button>
                        <Button variant="primary"
                                disabled={!(newUserFirstName.length>0 && validEmail)}
                                onClick={this.handleSubmitUserDetails}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    null,
    {listUsers, displayNotification}
)(AddUserModal);
