import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav, Container} from 'react-bootstrap';
import IPHierarchyList from './IPHierarchyList';
import IPHierarchyLevels from './IPHierarchyLevels';
import listLinks from "../../../actions/api/links/list";
import ErrorAlert from 'components/ErrorAlert';


class IPHierarchy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'levels',
            activeLicensorTabID: '',
            licensorListData: [],
        };
    };

    componentDidMount() {
        this.getLicensorsList();
    }

    toggleView = (e, navtab) => {
        e.preventDefault();
        this.setState({tab: navtab});
    }

    getLicensorsList = () => {
        let {selectedOrg} = this.props;
        let {organisation_id, type} = selectedOrg || {};
        return listLinks(organisation_id, type)
            .then((data) => {
                this.setState({
                    licensorListData: data,
                    activeLicensorTabID: data[0].licensor_organisation_id
                });
            });
    }

    render() {
        const { tab,activeLicensorTabID } = this.state;
        const {selectedOrg = {}, errors, revalidate} = this.props;
        const {name = '', organisation_id = ''} = selectedOrg;

        return (
            <div className="company-informtion data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    {/*<h2 className="mb-3">{name}</h2>*/}
                    <Nav defaultActiveKey="info" className="mt--2 pr-0 mb-3">
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'levels' ? 'active' : ''}`}
                                      onClick={e => this.toggleView(e, 'levels')}>
                                Levels
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'list' ? 'active' : ''}`}  onClick={e => this.toggleView(e, 'list')}>
                                Intellectual Property
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    {this.state.tab === 'levels'
                        && <IPHierarchyLevels
                            selectedOrg={selectedOrg}
                            name={name}
                            organisationId={organisation_id}
                            activeLicensorTabID={activeLicensorTabID}
                        />
                    }
                    {this.state.tab === 'list'
                        && <IPHierarchyList
                            selectedOrg={selectedOrg}
                        />
                    }
                </Container>
            </div>
        );
    }
}

export default connect(
    null
)(IPHierarchy);
